<script>
import appConfig from "@/app.config";

export default {
  page: {
    title: "Payment Still On Progress",
    meta: [{ name: "description", content: appConfig.description }]
  }
}
</script>
<template>
  <div class="account-pages my-5 pt-5" style="overflow: hidden;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h4 class="display-2 fw-medium">
              <!-- 4<i class="bx bx-buoy bx-spin text-primary display-3"></i>4 -->
            <img src="@/assets/images/payment-success.png" alt class="img-fluid" style="max-width: 60%;"/>
            </h4>
            <h4 class="text-uppercase">Terima Kasih </h4>
            <div class="mt-1 text-center">
                <p>
                  Pembayaranmu sudah kami terima <br>
                  Mohon tunggu ±3 menit, sistem sedang memproses laporan pembayaranmu
                </p>
                <p>Simpan selalu bukti transaksi pembayaran anda,<br/>karena satu-satunya bukti pembayaran yang sah adalah bukti transaksi berhasil dari masing-masing channel pembayaran.<br/><br/>Kami merekomendasikan anda untuk selalu menyimpan setiap bukti transaksi pembayaran berhasil dari channel apapun karena itu akan membantu anda saat terjadi masalah pembayaran tidak masuk ke sistem keuangan sekolah. Terima kasih.</p>
              <!-- <router-link tag="a" class="btn btn-primary" style="width: 100%" to="/">Back to Payment</router-link> -->
            </div>
            <div class="mt-1 text-center">
                <router-link class="btn btn-primary" :to="{name : 'payment', query : {appRoute : this.$route.query.appRoute}}"><i class="fa fa-spinner"></i> Muat Ulang</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

